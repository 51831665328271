import React, { useState, useEffect } from "react";
import "./SelectVisitor.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { NavLink, useHistory } from "react-router-dom";
import Logo from "../Logo/logo";
import { VisitorTypeUrl } from "../../Services/ApiEndPoints";
import { APICALL } from "../../Services/ApiServices";
import { t } from '../../Translation/Translation';
import { DataTranslations } from "../../Services/ApiEndPoints";
import qrcode from "../../Static/images/QR-Latest_Gif.gif";
import BarcodeReader from 'react-barcode-reader';
import { CheckAppointmentsAppUrl, CheckTheFlow } from "../../Services/ApiEndPoints";
import GetRedirectionUrl from "../../Utilities/GetRedirectionUrl";
import  GetRedirectionUrlHome from "../../Utilities/GetRedirectionUrlHome";
import { useParams } from "react-router";
import Popup from "../../Utilities/Popup/modelPopup";
import { useLocation } from 'react-router-dom';



// screen types
// 1 => I have appointment/Interview
// 2 => I dont have an appointment
// 3 => Contractor
// 4 => Common

// const items = {
//   "4": { route: "/delivery",  key: 'delivery', screenType: '4' },
//   "1": { route: "/with-appointment", key: 'withAppointment', screenType: '1' },
//   "2": { route: "/without-appointment", key: 'withoutAppointment', screenType: '2' },
//   "3": { route: "/without-appointment",key: 'contractor', screenType: '3' },
//   // "1": { route: "/interview",key: 'interview', screenType: '1' },
// }

const items = {
  "4": { route: "/common",  key: 'common', screenType: 'common' },
  "1": { route: "/with-appointment", key: 'withAppointment', screenType: 'emailAndScan' },
  "2": { route: "/without-appointment", key: 'withoutAppointment', screenType: 'visitorDetails' },
  "3": { route: "/without-appointment",key: 'contractor', screenType: 'visitorDetails' },
  "": { route: "/interview",key: 'interview', screenType: 'emailAndScan' },
}
function SelectVisitor(props) {

  const history = useHistory();
  const [dataList, setDataList] = useState();
  const [allDetails, setAllDetails] = useState();
  const [style, setStyle] = useState();
  const [pageNo] = useState(0);
  const [filteredData, setFilteredData] = useState();

  const [errormessage, setErrormessage] = useState();
  const [displayError, setDisplayError] = useState();
  const [popup, setPopup] = useState('');

  const params = useParams()
  const location = useLocation();
  const pathRediect = location.pathname;
  const [email, setEmail] = useState('');
  const [body, setBody] = useState('');
  const[nextButtonStyle] = useState({'background': '#D20C14'});

  var header = (pathRediect === '/checkout') ? 'check-out' : 'check-in'
  var checkstatus = (pathRediect === '/checkout') ? 'check-out' : 'check-in'
  var response = {}
  var page = []
  var heading = `Visitor ${header}`
  var screen_key = '';
  if (localStorage.getItem("screen_key") && localStorage.getItem("screen_key") !== null) {
     screen_key = JSON.parse(localStorage.getItem("screen_key"));
  }

  // if (screen_key === 'withAppointment') {
  //   page = "emailAndScan"
  //   response = GetRedirectionUrl(page)
  // }else if(screen_key === 'interview'){
  //   page = "interview"
  //   checkstatus =  'interview'
  //   response = GetRedirectionUrl(page)
  // } else {
  //       response = {}
  //       response.back = '/'
  // }
  var final_location = '';
  if (localStorage.getItem("location") && localStorage.getItem("location") !== null) {
    let location2 = JSON.parse(localStorage.getItem("location"));
    final_location = location2;
  }

   /**
   * this function will close the error message popup
   */
   const closeAlertPopup = () => {
    setPopup("");
    setBody('');
  }

  const handleErrorWebCam = (error) => {
    console.log(error);
  }

  /**
   * this function will scan the qr code and send the scanned data to the backend
   * @param {*} result -->qr code data
   */
  const handleScanWebCam = (result) => {
    console.log("YESSSS working");
	result  = '9ef89ccba0a1688ed927d198ec0277b8';
    if (result) {

      let unique_data = {
        'unique_key': result
      }
	    //console.log(unique_data);return;
      APICALL.service(CheckTheFlow, "POST", unique_data)
      .then((apiResult) => {
        if (apiResult.flow_id === '0' || apiResult.flow_id === 0)
        {
            page = "emailAndScan"
            let lang = (localStorage.getItem('activeLanguage'))
            const withAppointmentData = filteredData.find(item => item.key === 'withAppointment');
            const typeId = withAppointmentData ? withAppointmentData.type_id : null;
            let homeData = {
              'home_screen': 'yes',
              'type_id': typeId,
              'language': lang,
              'screen_id' : '1'
            }
           // console.log(apiResult);return;
            localStorage.setItem("home_screen", JSON.stringify(homeData));
            response = GetRedirectionUrlHome(page)
	    

            let data1 = {
		              'scan': result,
		              'verify': 'scan',
		              'checkstatus': checkstatus,
		              'location': final_location
		            }
		        appointmentDetails(data1, 'scan')
        }
        else if(apiResult.flow_id === '1' || apiResult.flow_id === 1 )
        {
            page = "interview"
            let lang = (localStorage.getItem('activeLanguage'))
            const interviewData = filteredData.find(item => item.key === 'interview');
            const typeId = interviewData ? interviewData.type_id : null;
            let homeData = {
              'home_screen': 'yes',
              'type_id': typeId,
              'language': lang,
              'screen_id' : '1'
            }
            localStorage.setItem("home_screen", JSON.stringify(homeData));
            response = GetRedirectionUrlHome(page)

		let data1 = {
			                              'scan': result,
			                              'verify': 'scan',
			                              'checkstatus': checkstatus,
			                              'location': final_location
			                            }
		                        appointmentDetails(data1, 'scan')
        } else {
              response = {}
              response.back = '/'
        }
      })
      // check if user is check in status 
      // API call with email to check status
       /* let data1 = {
          'scan': result,
          'verify': 'scan',
          'checkstatus': checkstatus,
          'location': final_location
        }
        appointmentDetails(data1, 'scan')
	*/
    }
  }

  /**
   * This function is used for both checkin and checkout purpose
   * @param {*} data1 --> qr code data or email
   * @param {*} error -->type scan or email
   */
  const appointmentDetails = (data1, error) => {
    APICALL.service(CheckAppointmentsAppUrl, "POST", data1)
      .then((result) => {
        console.log(result);
        console.log(result.action);
        if(result.host_name !== undefined && result.host_name !== null){
          localStorage.setItem("hostname", JSON.stringify(result.host_name));
        }
        if (result.action) {
          console.log("innnn");
          setDisplayError(error)
          // if(result.action === 'interview'){
          //  let popupmsg = t('Please checkin through I have an interview')
          //  setPopup(popupmsg)
          // }else if(result.action === 'appointment'){
          //   let popupmsg = t('Please checkin through I have an appointment')
          //  setPopup(popupmsg)
          // }
          if (result.action === 'checkin') {
            console.log("checkin 106");
            //console.log(response.next);return;
            history.push({
              pathname: response.next
            });
          }
          else if(result.action === 'popup'){
            let popupmsg = ''
            if(result.popupType === 0){
            popupmsg = t("We notice that your meeting starts at")+" "+ result.appointmentTime['start'] 
            setBody(t("You can use the QR code as of")+" "+ result.appointmentTime['scan'])
            }else{
              popupmsg = t("Your Qr code has expired");
            }
            setPopup(popupmsg)
          }
          else if (result.action === 'checkout') {
            history.push({
              pathname: '/checkout-details',
            });
          } else if (result.action === 'pleasecheckin') {
            setErrormessage('Please check-in before check-out')

          }
          else if (result.action === 'alreadycheckin') {
            if(result.popupType === 'checkout'){
               setPopup(t('Please logout your previous appointment'))
              }else{
               setPopup(t('You have already registered'))
              }
           
          } else {
            if (error === 'scan') {
              setErrormessage('Please scan valid QR-code')
            } else {
              setErrormessage('No appointments for this Email')
            }
          }
        }
      })
  }


  /**
   * fetch the all visitor type from backend and show in the form of buttons
   */
  useEffect(() => {
    localStorage.removeItem("details");
    localStorage.removeItem("hostname");
    localStorage.removeItem("next_screen");
    localStorage.removeItem("next");
    localStorage.removeItem("prev");
    localStorage.removeItem("screen_type");
    localStorage.removeItem("screen_key");
    localStorage.removeItem("home_screen");

    

    APICALL.service(DataTranslations, "GET").then((result) => {
        if (result !== null) {
          localStorage.setItem("translations", JSON.stringify(result));
        }
      });

    if (localStorage.getItem("location") && localStorage.getItem("location") !== null) {
      
      var location = JSON.parse(localStorage.getItem("location"))
      let locationId = location['value']
      let lang = (localStorage.getItem('activeLanguage'))
      let data = {
        'locationId': locationId,
        'language': lang
      }
      APICALL.service(VisitorTypeUrl, "POST", data)
        .then((result) => {
          if (result['DATA'] !== undefined) {
            let visitor = []
            setAllDetails(result['DATA'])
            if (result['DATA'][pageNo]['screenType'] === 'visitorType') {
              (result['DATA'][pageNo]['screenDetails']).map((value1, i1) => {
                console.log(value1);
                if (items[value1['screen_id']] !== undefined ) {                  
                  console.log("jjjj");

                  if ([value1['screenType']] == 'visitorDetails'){
                   console.log("if");
                    visitor.push({ 'label': [value1['title']], 'route':items[value1['screen_id']]['route'], 'screenType':items[value1['screen_id']]['screenType'], 'key': items[value1['screen_id']]['key'], 'screen_id':value1['screen_id'], 'type_id': value1['type_id']})
                  }else{
                    console.log(items);
                    console.log(items[value1['screen_id']]['key']);
                    console.log(value1['title']);
                    console.log("else");
	            const tail_title = value1['title'];
                    //if(value1['title'] == 'Interview' || value1['title'] == 'interview')
		    if(tail_title.includes('Interview') || tail_title.includes('interview'))
                    {
                      visitor.push({ 'label': [value1['title']], 'route':'/interview', 'screenType':items[value1['screen_id']]['screenType'], 'key': 'interview', 'screen_id':value1['screen_id'], 'type_id': value1['type_id']})
                    }
                    else
                    {
                      visitor.push({ 'label': [value1['title']], 'route':items[value1['screen_id']]['route'], 'screenType':items[value1['screen_id']]['screenType'], 'key': items[value1['screen_id']]['key'], 'screen_id':value1['screen_id'], 'type_id': value1['type_id']})
                    }
                    // visitor.push({ 'label': [value1['title']], 'route':items[value1['screen_id']]['route'], 'screenType':items[value1['screen_id']]['screenType'], 'key': items[value1['screen_id']]['key']})
                  }
                } 
                else {
                  visitor.push({ 'label': [value1['title']], 'route': "/visitor-type-new-screen", 'screenType': 'newScreen', 'entryId': value1['entryId'], 'key': 'newScreen' })
                }
              })
              setDataList(visitor);
              console.log(visitor)
              let filteredData = visitor.filter(item => item.screen_id === '1');
              setFilteredData(filteredData);
              console.log(filteredData);
              if(visitor.length === 2 || visitor.length === 1 ){
              setStyle('col-md-12 col-lg-12 ')
              }
              else if( visitor.length === 3 || visitor.length === 4 ){
                setStyle('col-md-6 col-lg-6 div_max_width_3')
                // setStyle('col-md-5 col-lg-5 div_max_width_3')

              }else{
                setStyle('col-md-4 col-lg-4 div_max_width_5')
              }
            }
          }
        })
    } else {
      history.push({
        pathname: "/select-location"

      });
    }
  }, []);


  /**
   * on click of particular visitor type button navigate to that page
   * @param {*} route 
   * @param {*} key 
   * @param {*} array 
   * @param {*} index 
   * @param {*} newItem 
   * @param {*} entryId 
   */
  const redirection = (route, key, array, index, newItem, type_id, screen_id) => {
    console.log("126");
    console.log(key);
    var data = [
      ...array.slice(0, index + 1),
      newItem,
      ...array.slice(index + 1)
    ];
    // console.log(type_id);
    // console.log(screen_id);return;
    // localStorage.setItem("details", JSON.stringify(data));
    localStorage.setItem("details", JSON.stringify(array));

    // let pathname = route + '/' + page + '/' + key + '/' + entryId;
    // console.log(pathname);return;
    // pathname: route + '/' + page + '/' + key + '/' + entryId,
    // pathname: route + '/screen/' + screen_id + '/' + entryId,

    let page = pageNo + 1
    localStorage.setItem("screen_key", JSON.stringify(key));
    history.push({
      // pathname: route + '/screen'
      pathname: route + '/screen/' + screen_id + '/' + type_id,
    });
    
  }


  /**
   * save the selected language to the local storage
   * @param {*} lang 
   */
  const saveLang = (lang) => {

    localStorage.setItem('activeLanguage', lang);
    window.location.reload();

  }

  return (
    <div className="visitor_main col-md-12 col-lg-12 col-sm-12 p-0">
      <Logo/>
      <div className="body_sec_visitor">
        {/* <div className="col-md-12 row m-0   col-lg-12 col-sm-12 div_center mt-5 pt-5 col-xl-12 m-auto"> */}
        <div className="col-md-12 row m-0   col-lg-12 col-sm-12 div_center mt-5 pt-5 col-xl-8 m-auto">
          {/* <div className="col-md-4 col-lg-4 col-xxl-3 col-sm-12">
            <div className="col-12 vertical-middle pt-5 qrcode_height_sec">
              <h5 className="show_qr pb-3">{t('Show me QR code')}</h5>
              <div className="">
              <img className="qr_sec_img" alt="machine" src={qrcode}></img>
              </div>
            
              <BarcodeReader
              onError={handleErrorWebCam}
              onScan={handleScanWebCam} 
              />
              {errormessage && displayError === 'scan' ?
                <div className='error-message2 scan-error-msg'>
                  {t(errormessage)}
                </div> : ''
              }
            
            </div>
          </div> */}

          {/* <div className="col-md-8 col-lg-8 col-xxl-9 col-sm-12 align-self-center"> */}

              <BarcodeReader
                onError={handleErrorWebCam}
                onScan={handleScanWebCam} 
              />
              {errormessage && displayError === 'scan' ?
                <div className='error-message2 scan-error-msg'>
                  {t(errormessage)}
                </div> : ''
              }
            {/* <div className="row">
            <button onClick={handleScanWebCam} className="btn_select div-selectVisitor">
               {"click here"}
            </button>
            </div> */}
            <div className="row">
              {dataList !== undefined && 
                dataList.map((tab, index) => (
                    <div className={style} >
                    <button key={`tab-${index}`}
                      onClick={() => redirection(tab.route, tab.key, allDetails, pageNo, { 'screenType': tab.screenType, 'screenDetails': tab.type_id }, tab.type_id, tab.screen_id)}
                      className="btn_select div-selectVisitor "
                    > {t(String(tab.label))}</button>
                  </div>
                ))}
            </div>
          {/* </div> */}
        </div>
      </div>
      <div className="row  col-md-12 footer_sec pt-3 pb-3 col-lg-12 col-sm-12 m-0 p-0">
        <div className="col-6 lang-space p-0">
          <div className="col-md-12 col-lg-12 col-sm-12 p-0" >
            <ul className="lang_list">
              <li className={(localStorage.getItem('activeLanguage')) === 'en' ? 'selected  lang' : 'lang'}><button className={(localStorage.getItem('activeLanguage')) === 'en' ? 'selected_lang' : ''} onClick={() => saveLang('en')}>EN</button></li>
              <li className={(localStorage.getItem('activeLanguage')) === 'fr' ? 'selected  lang' : 'lang'}><button className={(localStorage.getItem('activeLanguage')) === 'fr' ? 'selected_lang' : ''} onClick={() => saveLang('fr')}>FR</button></li>{" "}
              <li className={(localStorage.getItem('activeLanguage')) === 'nl' ? 'selected  lang' : 'lang'}><button className={(localStorage.getItem('activeLanguage')) === 'nl' ? 'selected_lang' : ''} onClick={() => saveLang('nl')}>NL</button></li>{" "}
            </ul>
          </div>{" "}
        </div>{" "}
        <div className="col-6 div_checkout p-0">
          <NavLink to="/checkout">
            <button className="selectVisitor_check mb-0">{t('CHECK OUT')}  </button>{" "}
          </NavLink>{" "}
        </div>{" "}
      </div>{" "}

    </div>
  );
}

export default SelectVisitor;
